import React from 'react'

function ChartForecast() {
  return (
    <div className="App">
      <h1>Chart Forecast</h1>    
    </div>
  );
}

export default ChartForecast;
